import * as React from "react";
import { graphql } from "gatsby";
import PageComponent from "src/components/pages/blog";

const BlogPage = ({ data, location }) => {
  const posts = data.allFile.nodes.map((node) => ({
    date: node.childMdx.frontmatter.date,
    title: node.childMdx.frontmatter.title,
    id: node.childMdx.id,
    body: node.childMdx.body,
    excerpt: node.childMdx.excerpt,
    href: `/blog/${node.childMdx.slug}`,
  }));

  const copy = {
    morePosts: "קרא עוד",
  };

  return (
    <PageComponent
      lang="he"
      location={location}
      posts={posts}
      copy={copy}
      pageTitle="בלוג"
    />
  );
};

export const query = graphql`
  query ListBlogPostsHE {
    allFile(
      filter: {
        childMdx: { id: { ne: null } }
        sourceInstanceName: { eq: "he/blog" }
      }
      sort: { fields: childMdx___frontmatter___date, order: DESC }
    ) {
      nodes {
        childMdx {
          body
          id
          excerpt(truncate: true)
          slug
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
          }
        }
      }
    }
  }
`;

export default BlogPage;

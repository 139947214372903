import * as React from "react";
import Layout from "src/components/layout";
import { Link } from "gatsby";

const PageComponent = ({ location, posts, copy, pageTitle, lang }) => {
  const prefix = lang === "en" ? "" : `/${lang}`;
  return (
    <Layout pageTitle={pageTitle} location={location} lang={lang}>
      <section className="inner-content mx-auto lg:flex lg:flex-wrap my-16">
        {posts.slice(0, 3).map((post, key) => (
          <article
            key={key}
            className="p-4 mx-auto sm:w-5/6 md:w-2/3 lg:w-1/3 "
          >
            <div className="shadow-lg p-8 flex flex-col h-full">
              <h3 className="text-blue text-lg font-semibold ">
                <Link to={`${prefix}${post.href}`}> {post.title}</Link>
              </h3>
              <p className="italic pt-1 pb-2">{post.date}</p>
              <p className="flex-1">{post.excerpt}</p>
              <div>&nbsp;</div>
            </div>
          </article>
        ))}
      </section>
      <section className="inner-content mx-auto my-16 px-8 sm:w-5/6 md:w-2/3 lg:w-full">
        <h2 className="mb-2">{copy.morePosts}</h2>
        <ul>
          {posts.slice(3).map((post, key) => (
            <li key={key} className="mb-4">
              <Link
                className="text-blue font-semibold"
                to={`${prefix}${post.href}`}
              >
                {post.title}
              </Link>{" "}
              <p className="text-sm">{post.date}</p>
              <p className="italic text-sm lg:w-2/3">{post.excerpt}</p>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  );
};

export default PageComponent;
